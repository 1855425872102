import React from 'react';
import firebase from "../../firebase.js";

import BaseComponent from "../shared/BaseComponent.js";
import QuestionEditor from "./QuestionEditor";

class Create extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            q1bonus_description: '',
            q3bonus_description: '',
            focusedField: ''
        }

        this.gameQuery = firebase.firestore()
            .collection('games')
            .doc(this.props.game.id);

        this.allQuestionAnswerPairs = [];
        this.props.games.forEach(game => {
            console.log(game);
            if (game.id !== this.props.game.id && Object.keys(game.scores).length > 0) {
                let gameSections = [game.q1, game.q2, game.q3, game.q4, game.finals, game.halftime];
                gameSections.forEach(section => {
                    section.forEach(question => {
                        this.allQuestionAnswerPairs.push({
                            'question': question.text,
                            'answer': question.answer
                        });
                    })
                });
            }
        });
    }

    static getDerivedStateFromProps(props, state) {
        let q1bonus_description =
            state.focusedField === 'q1bonus_description'
                ? state.q1bonus_description : props.game.q1bonus_description;
        let q3bonus_description =
            state.focusedField === 'q3bonus_description'
                ? state.q3bonus_description : props.game.q3bonus_description;

        return {
            q1bonus_description: q1bonus_description,
            q3bonus_description: q3bonus_description
        };
    }

    onFocus = (e) => {
        this.setState({
            focusedField: e.target.name
        });
    }

    onBlur = (e) => {
        this.setState({
            focusedField: ''
        });

        this.gameQuery
            .update({
               [e.target.name]: e.target.value
            });
    }

    getTextareasForPhase = (phase) => {
        let indices = [0, 1, 2, 3]

        if (phase === 'halftime') {
            indices = [0, 1, 2, 3, 4];
        } else if (phase === 'finals') {
            indices = [0, 1];
        } else if (phase.indexOf('bonus') > -1) {
            indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        }

        return indices.map(index =>
            <QuestionEditor
                phase={phase}
                index={index}
                question={this.props.game[phase][index]}
                allQuestionAnswerPairs={this.allQuestionAnswerPairs}
                onFieldBlur={(e, fieldName, value) =>
                    this.updateField(e, phase, index, fieldName, value)} />
        );
    };

    updateField = (e, phase, index, fieldName, value) => {
        let updatedPhaseQuestions = this.props.game[phase];

        const updatedQuestion = updatedPhaseQuestions[index];
        updatedQuestion[fieldName] = value;

        // 2pt halftime clue answer - copy the answer into the other halftime questions.
        if (phase === 'halftime' && index === 4 && fieldName === 'answer') {
            updatedPhaseQuestions = updatedPhaseQuestions.map(question => {
                question.answer = value;
                return question;
            })
        }

        this.gameQuery
            .update({
                [phase]: updatedPhaseQuestions
            });
    }

    render() {
        if (!this.props.game) return null;

        return <div class='semitransparent'>
            <h3>First Quarter</h3>
            {this.getTextareasForPhase('q1')}

            <h3>Multimedia Round</h3>
            <textarea
                name='q1bonus_description'
                placeholder='multimedia round description'
                value={this.state.q1bonus_description}
                onChange={this.updateState}
                onFocus={this.onFocus}
                onBlur={this.onBlur} />
            {this.getTextareasForPhase('q1bonus')}

            <h3>Second Quarter</h3>
            {this.getTextareasForPhase('q2')}

            <h3>Halftime</h3>
            {this.getTextareasForPhase('halftime')}

            <h3>Third Quarter</h3>
            {this.getTextareasForPhase('q3')}

            <h3>Wordy Third Round Thing</h3>
            <textarea
                name='q3bonus_description'
                placeholder='wordy third round thing description'
                value={this.state.q3bonus_description}
                onChange={this.updateState}
                onFocus={this.onFocus}
                onBlur={this.onBlur} />
            {this.getTextareasForPhase('q3bonus')}

            <h3>Fourth Quarter</h3>
            {this.getTextareasForPhase('q4')}

            <h3>Finals</h3>
            {this.getTextareasForPhase('finals')}
        </div>;
    }
}

export default Create;