import React from 'react';
import firebase from "../../firebase.js";
import BaseComponent from "../shared/BaseComponent.js";
import './admin.css';

import Util from '../shared/Util.js';

class Grade extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            answers: [],
            showAllAnswers: false
        };

        this.registerSnapshotListener(this.props.game.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.game.id !== this.props.game.id) {
            this.registerSnapshotListener();
        }
    }

    registerSnapshotListener = () => {
        this.answersQuery = firebase.firestore()
            .collection('games')
            .doc(this.props.game.id)
            .collection('answers');

        if (this.unsubscribe) {
            this.unsubscribe();
        }

        this.unsubscribe = this.answersQuery
            .orderBy('timestamp')
            .onSnapshot((answersSnapshot) => {
                const allAnswers = [];
                answersSnapshot.forEach((answerDoc) => {
                    const answer = answerDoc.data();
                    answer.id = answerDoc.id;
                    allAnswers.push(answer);
                })

                this.setState({
                    answers: allAnswers
                })
            })
    }

    gradeAnswer = (answer, points) => {
        this.answersQuery
            .doc(answer.id)
            .update({
                graded: true,
                gradedPoints: parseFloat(points) || 0
            }).then(() => {
                this.rebuildScoreMap();
            });
    }



    rebuildScoreMap = () => {
        const scoreMap = {};
        const teams = new Set()

        this.state.answers.forEach(answer => {
           teams.add(answer.teamName)
        });


        teams.forEach(team => {
            const scoreObject = {
                scoreHistory: []
            }

            let total = 0;

            const answersForTeam = this.state.answers.filter(answer => answer.teamName === team)

            answersForTeam
                .filter(answer => answer.phase === 'q1')
                .forEach(answer => {
                    total += answer.gradedPoints;
                    scoreObject.scoreHistory.push(total);
                })

            let q1BonusTotal = answersForTeam
                .filter(answer => answer.phase === 'q1bonus')
                .reduce((total, answer) => total + parseFloat((answer.gradedPoints || 0)), 0)

            if (Util.comparePhases('q1bonus', this.props.game.currentPhase) <= 0) {
                total += q1BonusTotal
                scoreObject.scoreHistory.push(total)
            }

            answersForTeam
                .filter(answer => answer.phase === 'q2')
                .forEach(answer => {
                    total += answer.gradedPoints;
                    scoreObject.scoreHistory.push(total);
                })

            answersForTeam
                .filter(answer => answer.phase === 'halftime')
                .forEach(answer => {
                    total += answer.gradedPoints;
                    scoreObject.scoreHistory.push(total);
                })

            answersForTeam
                .filter(answer => answer.phase === 'q3')
                .forEach(answer => {
                    total += answer.gradedPoints;
                    scoreObject.scoreHistory.push(total);
                })

            let q3BonusTotal = answersForTeam
                .filter(answer => answer.phase === 'q3bonus')
                .reduce((total, answer) => total + parseFloat((answer.gradedPoints || 0)), 0)

            if (Util.comparePhases('q3bonus', this.props.game.currentPhase) <= 0) {
                total += q3BonusTotal
                scoreObject.scoreHistory.push(total)
            }

            answersForTeam
                .filter(answer => answer.phase === 'q4')
                .forEach(answer => {
                    total += answer.gradedPoints;
                    scoreObject.scoreHistory.push(total);
                })

            answersForTeam
                .filter(answer => answer.phase === 'finals')
                .forEach(answer => {
                    total += answer.gradedPoints;
                    scoreObject.scoreHistory.push(total);
                })

            scoreMap[team] = scoreObject;
        });

        firebase.firestore()
            .collection('games')
            .doc(this.props.game.id)
            .update({
                scores: scoreMap
            })
    }

    answerRows = () => {
        return this.state.answers
            .filter(answer => this.state.showAllAnswers ? true : !answer.graded)
            .map(answer => {
            return <tr>
                <td>{answer.teamName}</td>
                <td>{answer.text}</td>
                <td>{answer.correctAnswer}</td>
                <td>{answer.points}</td>
                <td>
                    <input
                        type='number'
                        value={answer.gradedPoints || 0}
                        onChange={e => this.gradeAnswer(answer, e.target.value)} />

                    <button
                        onClick={() => this.gradeAnswer(answer, answer.points)}>
                        Right
                    </button>
                    <button
                        onClick={() => this.gradeAnswer(answer, answer.points / 2)}>
                        Half Credit
                    </button>
                    <button
                        onClick={() =>
                            this.gradeAnswer(answer,
                                (answer.phase === 'halftime' && answer.points > 0)
                                    ? -5
                                    : answer.phase === 'finals'
                                        ? -answer.points / 2
                                        : 0)}>
                        Wrong
                    </button>
                </td>
            </tr>
        });
    }

    showAllAnswers = (show) => {
        this.setState({
           showAllAnswers: show
        });
    }

    getAnswerCount = () => {
        const currentPhase = this.props.game.currentPhase;
        return this.state.answers
            .filter(answer => answer.phase === currentPhase)
            .filter(answer => (!Util.isHalftime(currentPhase) && !Util.isBonus(currentPhase))
                ? answer.question === this.props.game.currentQuestion : true)
            .length;
    }

    render() {
        return <div class='semitransparent gradecontainer'>
            <button
                onClick={e => this.showAllAnswers(false)}>Show unscored answers only</button>
            &nbsp;
            <button
                onClick={e => this.showAllAnswers(true)}>Show all answers</button>
            <p />
            Answers submitted for current question: {this.getAnswerCount()}
            <p />
            <table>
                <colgroup>
                    <col width='25%' />
                    <col width='15%' />
                    <col width='15%' />
                    <col width='10%' />
                    <col width='30%' />
                </colgroup>
                <tr>
                    <th>Team Name</th>
                    <th>Answer</th>
                    <th>Correct Answer</th>
                    <th>Points Wagered</th>
                    <th>Scored Points</th>
                </tr>
                {this.answerRows()}
            </table>
        </div>;
    }
}

export default Grade;