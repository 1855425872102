import React from 'react';
import BaseComponent from '../shared/BaseComponent';

import Game from './Game.js';
import GameSelector from "../shared/GameSelector";

import './play.css'

class Play extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            teamName: window.localStorage.getItem('teamName')
        };
    }

    updateTeamName = (e) => {
        this.updateState(e);
        window.localStorage.setItem('teamName', e.target.value);
    }

    render() {
        if (!this.props.games) return;

        return (<div>
            <div id='header' class='semitransparent'>
                <label for='teamName'>you are the </label>
                <input
                    type='text'
                    autoComplete='off'
                    name='teamName'
                    value={this.state.teamName}
                    onChange={this.updateTeamName}
                    placeholder='(some team name)' />
                <label> and you're playing</label>
                <GameSelector
                    games={this.props.games}
                    selectedGameId={this.props.selectedGameId}
                    disabled={!this.state.teamName || this.state.teamName.length === 0}
                    onSelectedGameIdChanged={this.props.onSelectedGameIdChanged}/>
            </div>

            {this.props.selectedGame &&
                <Game
                    selectedGame={this.props.selectedGame}
                    teamName={this.state.teamName} />}
        </div>)
    }
}

export default Play;
