import React from 'react';

class Util {
    static getPhaseOrder() {
        return ['q1', 'q1bonus', 'q2', 'halftime', 'q3', 'q3bonus', 'q4', 'finals'];
    }

    static comparePhases(phase1, phase2) {
        return this.getPhaseOrder().indexOf(phase1) - this.getPhaseOrder().indexOf(phase2);
    }

    static htmlForQuestionText(question) {
        if (Util.isImageFile(question.text)) {
            return <img src={question.text} />;
        } else if (Util.isAudioFile(question.text)) {
            return <audio controls loop preload='auto' src={question.text} />;
        } else if (Util.isVideoFile(question.text)) {
            return <video controls loop preload='auto' src={question.text} />;
        } else {
            return question.text;
        }
    }

    static isBonus(phase) {
        return phase.indexOf('bonus') > -1;
    }

    static isHalftime(phase) {
        return phase === 'halftime';
    }

    static getNextPhaseAndIndex(phase, index) {
        const inNormalQuarter =
            phase === 'q1' || phase === 'q2' || phase === 'q3' || phase === 'q4';
        const inHalftime = phase === 'halftime';
        const inFinal = phase === 'finals';
        const hasNextQuestion =
            (inNormalQuarter && index < 3) ||
            (inHalftime && index < 4) ||
            (inFinal && index === 0);

        let phaseAndIndex = {
            phase: phase,
            index: index
        }

        if (hasNextQuestion) {
            phaseAndIndex = {
                phase: phase,
                index: index + 1
            };
        } else if (phase !== 'finals') {
            const phaseOrder = Util.getPhaseOrder();
            phaseAndIndex = {
                phase: phaseOrder[phaseOrder.indexOf(phase) + 1],
                index: 0
            };
        }

        return phaseAndIndex;
    }

    static getPrevPhaseAndIndex(phase, index) {
        const inNormalQuarter =
            phase === 'q1' || phase === 'q2' || phase === 'q3' || phase === 'q4';
        const inHalftime = phase === 'halftime';
        const inFinal = phase === 'finals';
        const hasPrevQuestion =
            (inNormalQuarter && index > 0) ||
            (inHalftime && index > 0) ||
            (inFinal && index === 1);

        let phaseAndIndex = {
            phase: phase,
            index: index
        }

        if (hasPrevQuestion) {
            phaseAndIndex = {
                phase: phase,
                index: index - 1
            };
        } else if (phase !== 'q1') {
            const phaseOrder = Util.getPhaseOrder();
            const prevPhase = phaseOrder[phaseOrder.indexOf(phase) - 1];
            const startIndex =
                Util.isHalftime(prevPhase)
                    ? 4
                    : Util.isBonus(prevPhase)
                        ? 0
                        : 3;

            phaseAndIndex = {
                phase: phaseOrder[phaseOrder.indexOf(phase) - 1],
                index: startIndex
            };
        }

        return phaseAndIndex;
    }

    static getCategoryString(category, phase, index) {
        if (phase === 'halftime') {
            return <>halftime question! for your <b>{10 - (index * 2)} point</b> clue:</>
        } else if (phase === 'finals') {
            return <>question <b>#{index + 1}</b> in the final round is in <b>{category}</b>:</>
        } else if (phase === 'q1bonus') {
            return <>first quarter <b>multimedia round!</b></>
        } else if (phase === 'q3bonus') {
            return <>third quarter <b>wordy round!</b></>
        } else {
            return <>
                question <b>#{index + 1}</b> in the <b>{this.getPhaseString(phase)}</b>
                &nbsp;is in <b>{category}</b>
            </>
        }
    }

    static getRulesString(phase) {
        if (phase === 'halftime') {
            return 'lose 5 points if you are wrong at any point. choose 0 points to not lose any points if you give up.'
        } else if (phase === 'finals') {
            return 'lose half of your wagered points if you are wrong. you must submit an answer.';
        } else if (phase === 'q1bonus' || phase === 'q3bonus') {
            return '(2 points each)';
        }
    }

    static isAudioFile(fileName) {
        return this.isURL(fileName) && (fileName.indexOf('.mp3') !== -1
            || fileName.indexOf('.m4a') !== -1
            || fileName.indexOf('.wav') !== -1);
    }

    static isVideoFile(fileName) {
        return this.isURL(fileName) && (fileName.indexOf('.mp4') !== -1);
    }

    static isImageFile(fileName) {
        return this.isURL(fileName)
            && !this.isVideoFile(fileName) && !this.isAudioFile(fileName);
    }

    static isURL(fileName) {
        return fileName.indexOf('http://') === 0 || fileName.indexOf('https://') === 0;
    }


    static isMultimediaFile(fileName) {
        return this.isAudioFile(fileName)
            || this.isVideoFile(fileName)
            || this.isImageFile(fileName);
    }

    static getPhaseString(phase) {
        switch (phase) {
            case 'q1':
                return 'first quarter';
            case 'q2':
                return 'second quarter';
            case 'q3':
                return 'third quarter';
            case 'q4':
                return 'fourth quarter';
            case 'q1bonus':
                return 'multimedia round';
            case 'q3bonus':
                return 'wordy third round thing';
            case 'finals':
                return 'finals';
            case 'halftime':
                return 'halftime';
        }
    }
}

export default Util;