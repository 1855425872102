import React, { Component } from 'react';
import './admin.css';

class DumpQuestions extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
  }

  render() {
    let questionLine = [];
    this.props.games.forEach(game => {
      let gameSections = [game.q1, game.q2, game.q3, game.q4, game.finals, game.halftime];
      gameSections.forEach(section => {
        section.forEach(question => {
          questionLine.push(<div>{question.text}: <b>{question.answer}</b><p/></div>);
        })
      })
    });
    return <div class="semitransparent">
      {questionLine}
    </div>;
  }
}

export default DumpQuestions;
