import React from 'react';
import firebase from "../../firebase.js";

import BaseComponent from "../shared/BaseComponent.js";
import Util from '../shared/Util.js';
import Question from "../play/Question";

class Manage extends BaseComponent {
    constructor(props) {
        super(props);
    }

    advanceGame = () => {
        const phase = this.props.game.currentPhase;
        const question = this.props.game.currentQuestion;

        const phaseAndIndex = Util.getNextPhaseAndIndex(phase, question);

        firebase.firestore()
            .collection('games')
            .doc(this.props.game.id)
            .update({
                currentPhase: phaseAndIndex.phase,
                currentQuestion: phaseAndIndex.index
            });

        window.scrollTo(0, 0);
    }

    goBack = () => {
        //if (window.confirm('Are you sure you want go back to the previous question?')) {
            const prev = Util.getPrevPhaseAndIndex(this.props.game.currentPhase, this.props.game.currentQuestion);
            firebase.firestore()
                .collection('games')
                .doc(this.props.game.id)
                .update({
                    currentPhase: prev.phase,
                    currentQuestion: prev.index
                });
        //}
    }

    resetGame = () => {
        if (window.confirm('You sure? This will reset to question #1 in the first quarter and delete all answers.')) {
            firebase.firestore()
                .collection('games')
                .doc(this.props.game.id)
                .update({
                    started: false,
                    currentPhase: 'q1',
                    currentQuestion: -1,
                    scores: {}
                });

            firebase.firestore()
                .collection('games')
                .doc(this.props.game.id)
                .collection('answers')
                .get()
                .then(answersSnapshot => {
                    answersSnapshot.forEach(answer => {
                        firebase.firestore()
                            .collection('games')
                            .doc(this.props.game.id)
                            .collection('answers')
                            .doc(answer.id)
                            .delete();
                    })
                });
        }
    }

    getNextPhaseAndIndex = () => {
        return Util.getNextPhaseAndIndex(
            this.props.game.currentPhase, this.props.game.currentQuestion)
    }

    getPrevPhaseAndIndex = () => {
        return Util.getPrevPhaseAndIndex(
            this.props.game.currentPhase, this.props.game.currentQuestion)
    }

    getNextQuestion = () => {
        const phaseAndIndex = this.getNextPhaseAndIndex();
        return this.props.game[phaseAndIndex.phase][phaseAndIndex.index]
    }

    getCategories = () => {
        const phase = this.getNextPhaseAndIndex().phase;
        const categories = this.props.game[phase].map(question => {
            return question.category;
        })

        return categories;
    }

    getAnswer = () => {
        const phase = this.props.game.currentPhase;
        const index = this.props.game.currentQuestion;

        if (index < 0) {
            return "";
        }

        if (!Util.isBonus(phase)) {
            return <>
                <b>{this.props.game[phase][index].answer}</b>
            </>
        } else {
             return this.props.game[phase].map(question => {
                if (phase === 'q1bonus'
                        || (phase === 'q3bonus'
                                && Util.isMultimediaFile(question.text))) {
                    return <div class='semitransparent'>
                        <br/>
                        {Util.isImageFile(question.text) && <img src={question.text} />}
                        {Util.isAudioFile(question.text) &&
                            <audio controls loop preload='auto' src={question.text} />}
                        {Util.isVideoFile(question.text) &&
                            <video controls loop preload='auto' src={question.text} />}
                        <br/><b>{question.answer}</b><br />
                        <p/>
                    </div>
                } else {
                    return <>
                        <p/>{question.text}: <b>{question.answer}</b>
                    </>
                }
            })
        }
    }

    render() {
        const nextPhaseAndIndex = this.getNextPhaseAndIndex();
        const nextPhase = nextPhaseAndIndex.phase;
        const nextIndex = nextPhaseAndIndex.index;

        return <div class='semitransparent management'>
            {this.getAnswer() &&
            <>
                The answer to the current question is: {this.getAnswer()}
                <p />
            </>
            }

            Here's what you say next:
            <p/>
            {(nextIndex === 0 || (nextPhase === 'q1' && nextIndex === 1))
                && !Util.isBonus(nextPhase)
                && !Util.isHalftime(nextPhase) &&
                <>
                    Your {Util.getPhaseString(this.getNextPhaseAndIndex().phase)} categories are:<br/>
                    {this.getCategories().join(', ')}
                    <p/>
                </>
            }

            <Question
                phase={nextPhase}
                index={nextIndex}
                q1description={this.props.game.q1bonus_description}
                q3description={this.props.game.q3bonus_description}
                question={this.getNextQuestion()} />

            <button onClick={this.advanceGame}>
                {nextPhase === 'q1bonus' && 'Show multimedia round'}
                {nextPhase === 'q3bonus' && 'Show wordy round'}
                {nextPhase !== 'q1bonus' && nextPhase !== 'q3bonus' && 'Done reading, reveal question'}
            </button>
            <p/>
            <button onClick={this.resetGame}>Reset to Q1</button>
            &nbsp;
            <button onClick={this.goBack}>Previous question</button>
        </div>;
    }
}

export default Manage;