import React from 'react';
import firebase from '../../firebase.js';
import BaseComponent from '../shared/BaseComponent';

import Question from './Question';
import StandardAnswerCard from './StandardAnswerCard.js';
import BonusAnswerCard from "./BonusAnswerCard.js";
import Scoreboard from '../shared/Scoreboard.js';
import Overview from './Overview.js';
import Util from '../shared/Util.js';

class Game extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            myAnswers: []
        };

        this.gameQuery = firebase.firestore()
            .collection('games')
            .doc(this.props.selectedGame['id']);

        this.registerListener();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.teamName !== this.props.teamName) {
            this.registerListener();
        }
    }

    registerListener = () => {
        if (this.cancelAnswersListener) {
            this.cancelAnswersListener();
        }

        this.cancelAnswersListener = this.gameQuery
            .collection('answers')
            .onSnapshot(answersForGameSnapshot => {
                const myAnswers = [];
                const allAnswers = [];

                answersForGameSnapshot.forEach(answerSnapshot => {
                    if (answerSnapshot.data().teamName === this.props.teamName) {
                        myAnswers.push(answerSnapshot.data());
                    }

                    allAnswers.push(answerSnapshot.data());
                });

                this.setState({
                    myAnswers: myAnswers,
                    allAnswers: allAnswers
                });
            })
    }

    getQuestion = () => {
        const game = this.props.selectedGame;
        const phase = game['currentPhase'];
        const question = game['currentQuestion'];

        return game[phase][question];
    }

    getBonusQuestions = () => {
        const game = this.props.selectedGame;
        const phase = game['currentPhase'];

        return game[phase];
    }

    getUsedPoints = () => {
        return this.state.myAnswers
            .filter(answer => answer.phase === this.props.selectedGame['currentPhase'])
            .map(answer => answer.points);
    }

    getAllTeams = () => {
        let teams = new Set();
        if (this.state.allAnswers) {
            this.state.allAnswers.forEach(answer => {
                teams.add(answer.teamName);
            });
        }
        return teams;
    }

    getOtherAnswers = () => {
        if (this.state.allAnswers) {
            if (this.props.selectedGame['currentPhase'] === 'halftime') {
                return this.state.allAnswers
                    .filter(answer =>
                        answer.phase === this.props.selectedGame['currentPhase']);
            } else {
                return this.state.allAnswers
                    .filter(answer =>
                        answer.phase === this.props.selectedGame['currentPhase']
                        && answer.question === this.props.selectedGame['currentQuestion']);
            }
        } else {
            return null;
        }
    }

    getMyAnswer = () => {
        if (Util.isHalftime(this.props.selectedGame.currentPhase)) {
            return this.state.myAnswers
                .find(answer => answer.phase === this.props.selectedGame['currentPhase']);
        } else {
            return this.state.myAnswers
                .find(answer =>
                    answer.phase === this.props.selectedGame['currentPhase'] &&
                    answer.question === this.props.selectedGame['currentQuestion']);
        }

    }

    getMyBonusAnswers = () => {
        return this.state.myAnswers
            .filter(answer => answer.phase === this.props.selectedGame['currentPhase'])
            .sort((a1, a2) => a1.question - a2.question);
    }

    submitAnswer = (answer, points) => {
        this.gameQuery
            .collection('answers')
            .add({
                teamName: this.props.teamName,
                correctAnswer: this.getQuestion().answer,
                text: answer,
                points: parseInt(points),
                phase: this.props.selectedGame['currentPhase'],
                question: this.props.selectedGame['currentQuestion'],
                correct: false,
                graded: false,
                timestamp: +new Date()
            }).then();
    }

    submitBonusAnswers = (answers) => {
        console.log(answers);
        const batch = firebase.firestore().batch();

        answers.forEach((answer, index) => {
            const answerRef = this.gameQuery.collection('answers').doc();
            batch.set(answerRef, {
                teamName: this.props.teamName,
                correctAnswer: this.getBonusQuestions()[index].answer,
                text: answer,
                points: 2,
                phase: this.props.selectedGame['currentPhase'],
                question: index,
                correct: false,
                graded: false,
                timestamp: +new Date()
            });
        })

        batch.commit();
    }

    renderAllQuestions = () => {
        return <div class='semitransparent question-row'>
            question <b>#1</b> in the <b>first quarter</b> is in <b>Birds</b>:

        </div>
    }

    renderHistory = () => {
        return <div class='semitransparent question-row'>
            <table>
                <tr><b>First Quarter</b></tr>
                <tr>
                    <td><b>Birds</b></td>
                    <td>What is a good bird?</td>
                    <td>Parakeet</td>
                    <td>10</td>
                </tr>
            </table>
        </div>
    }

    render() {
        if (this.props.selectedGame.currentQuestion >= 0) {
            return <div id='game'>
                <div>
                    <Scoreboard
                            scores={this.props.selectedGame['scores']}/>
                </div>

                <div>

                    {!Util.isBonus(this.props.selectedGame.currentPhase)
                        &&
                        <div class='horizontal-question-wrapper'>

                            <Question
                                style={{marginTop: 15}}
                                phase={this.props.selectedGame['currentPhase']}
                                index={this.props.selectedGame['currentQuestion']}
                                q1description={this.props.selectedGame.q1bonus_description}
                                q3description={this.props.selectedGame.q3bonus_description}
                                question={this.getQuestion()}/>

                            {/** Normal questions, like quarters, halftime, or finals. */}
                            {!Util.isBonus(this.props.selectedGame.currentPhase) &&
                            <StandardAnswerCard
                                phase={this.props.selectedGame['currentPhase']}
                                index={this.props.selectedGame['currentQuestion']}
                                teams={this.getAllTeams()}
                                otherAnswers={this.getOtherAnswers()}
                                usedPoints={this.getUsedPoints()}
                                submittedAnswer={this.getMyAnswer()}
                                submitAnswer={this.submitAnswer}/>
                            }

                        </div>
                    }

                    {/** Q1 and Q3 bonus rounds. */}
                    {Util.isBonus(this.props.selectedGame.currentPhase) &&
                    <div>
                        <Question
                            style={{marginTop: 15}}
                            phase={this.props.selectedGame['currentPhase']}
                            index={this.props.selectedGame['currentQuestion']}
                            q1description={this.props.selectedGame.q1bonus_description}
                            q3description={this.props.selectedGame.q3bonus_description}
                            question={this.getQuestion()}/>
                        <BonusAnswerCard
                            phase={this.props.selectedGame['currentPhase']}
                            questions={this.getBonusQuestions()}
                            submittedAnswers={this.getMyBonusAnswers()}
                            submitAnswers={this.submitBonusAnswers}/>
                    </div>
                    }
                </div>

                <div style={{marginTop: 15}}>
                    <Overview
                        selectedGame={this.props.selectedGame}
                        myAnswers={this.state.myAnswers}/>
                </div>
            </div>
        } else {
            return <div class='standby semitransparent'>
                This game hasn't started yet. It'll probably be just a few more minutes.
            </div>
        }
    }
}

export default Game;