import React, { Component } from 'react';

class BaseComponent extends Component {
    updateState = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getSectionOrder = () => {
        return ['q1', 'q1bonus', 'q2', 'halftime', 'q3', 'q3bonus', 'q4', 'finals'];
    }
}

export default BaseComponent;