import React from 'react';
import BaseComponent from '../shared/BaseComponent';

import Util from "../shared/Util";

class StandardAnswerCard extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            draftAnswer: '',
            draftPoints: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.submittedAnswer && props.submittedAnswer.text) {
            return {
                draftAnswer: '',
                draftPoints: ''
            }
        }
    }

    getPointOptions() {
        if (this.props.phase === "q1" || this.props.phase === "q2") {
            return [
                <option value='1' disabled={this.props.usedPoints.indexOf(1) > -1}>1</option>,
                <option value='3' disabled={this.props.usedPoints.indexOf(3) > -1}>3</option>,
                <option value='5' disabled={this.props.usedPoints.indexOf(5) > -1}>5</option>,
                <option value='7' disabled={this.props.usedPoints.indexOf(7) > -1}>7</option>
            ]
        } else if (this.props.phase === "q3" || this.props.phase === "q4") {
            return [
                <option value='2' disabled={this.props.usedPoints.indexOf(2) > -1}>2</option>,
                <option value='4' disabled={this.props.usedPoints.indexOf(4) > -1}>4</option>,
                <option value='6' disabled={this.props.usedPoints.indexOf(6) > -1}>6</option>,
                <option value='8' disabled={this.props.usedPoints.indexOf(8) > -1}>8</option>
            ]
        } else if (this.props.phase === "finals") {
            return [
                <option value='2'>2</option>,
                <option value='4'>4</option>,
                <option value='6'>6</option>,
                <option value='8'>8</option>,
                <option value='10'>10</option>
            ]
        } else if (this.props.phase === "halftime") {
            return [
                <option value={10 - (this.props.index * 2)}>
                    {10 - (this.props.index * 2)}
                </option>,
                <option value={0}>
                    0
                </option>
            ]
        }
    }

    submitAnswer = () => {
        const maxBet = 10 - (this.props.index * 2);
        const aboveMax = Util.isHalftime(this.props.phase) && this.state.draftPoints > maxBet;

        const isGivingUpOnHalftime =
            Util.isHalftime(this.props.phase) && this.state.draftPoints == 0;

        if (isGivingUpOnHalftime
            || (this.state.draftPoints > 0 && this.state.draftAnswer.length > 0) && !aboveMax) {
            this.props.submitAnswer(this.state.draftAnswer, this.state.draftPoints);
        } else {
            window.alert('You have to both answer something, and pick a point value.');
        }
    }

    getPendingAnswersTable = () => {
        let answerCount = 0;
        let teamCount = 0;
        this.props.teams.forEach(team => {
            teamCount++;
            answerCount += this.props.otherAnswers.find(answer => answer.teamName === team) ? 1 : 0;
        });

        return <i class='rule'>{answerCount} of {teamCount} teams have submitted</i>
    }

    render() {
        return <div id='answer' class='semitransparent'>
            {this.props.submittedAnswer &&
            <>you answered:</> }

            {!this.props.submittedAnswer &&
            <>your answer:</> }
            <p />
            <input
                type='text'
                name='draftAnswer'
                value={
                    (this.props.submittedAnswer && this.props.submittedAnswer.text) ||
                    this.state.draftAnswer}
                disabled={this.props.submittedAnswer}
                placeholder='answer'
                autoComplete='false'
                onChange={this.updateState} />
            <p/>
            <select
                name='draftPoints'
                value={
                    (this.props.submittedAnswer && this.props.submittedAnswer.points) ||
                    this.state.draftPoints}
                disabled={this.props.submittedAnswer}
                onChange={this.updateState}>
                <option value='-1'>points?</option>
                {this.getPointOptions()}
            </select>
            <p />
            <button
                disabled={this.props.submittedAnswer}
                onClick={this.submitAnswer}>Submit Answer</button>
            <p />
            {this.getPendingAnswersTable()}
        </div>
    }
}

export default StandardAnswerCard;