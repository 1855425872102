import React from 'react';
import firebase from "../../firebase.js";
import BaseComponent from "../shared/BaseComponent.js";

import GameSelector from "../shared/GameSelector";
import Create from './Create.js';
import Manage from './Manage.js';
import Grade from './Grade.js';
import Question from "../play/Question.js";
import Scoreboard from "../shared/Scoreboard";

class Admin extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            draftGameName: '',
            answers: []
        };

        this.getAllAnswers();
    }

    getAllAnswers = () => {
        firebase.firestore()
            .collection('games')
            .get()
            .then((querySnapshot) => {
                let mergedString = "";
                querySnapshot.docs.map((doc) => {
                    mergedString += JSON.stringify(doc.data());
                })
            });
    }

    emptyQuestionsOfLength = (length) => {
        return Array(length).fill({
            category: '',
            text: '',
            answer: ''
        });
    }

    createGame = () => {
        if (this.state.draftGameName.length === 0) {
            window.alert('You have to name your game.');
            return;
        }

        firebase.firestore()
            .collection('games')
            .add({
                active: true,
                created: +new Date(),
                currentPhase: 'q1',
                currentQuestion: -1,
                name: this.state.draftGameName,
                q1: this.emptyQuestionsOfLength(4),
                q1bonus_description: '',
                q1bonus: this.emptyQuestionsOfLength(10),
                q2: this.emptyQuestionsOfLength(4),
                halftime: this.emptyQuestionsOfLength(5),
                q3: this.emptyQuestionsOfLength(4),
                q3bonus_description: '',
                q3bonus: this.emptyQuestionsOfLength(10),
                q4: this.emptyQuestionsOfLength(4),
                finals: this.emptyQuestionsOfLength(2),
                scores: []
            }).then((gameSnapshot) => {
                this.setState({
                    draftGameName: ''
                });

                this.props.onSelectedGameIdChanged(gameSnapshot.id);
            });
    }

    getQuestion = () => {
        const game = this.props.selectedGame;
        const phase = game['currentPhase'];
        const question = game['currentQuestion'];

        return game[phase][question];
    }

    render() {
        return <div class='admin'>

            <div id='header' className='semitransparent adminheader'>
                you're currently editing
                <GameSelector
                    games={this.props.games}
                    selectedGameId={this.props.selectedGameId}
                    onSelectedGameIdChanged={this.props.onSelectedGameIdChanged}/>

                , or you can&nbsp;
                <input
                    type='text'
                    name='draftGameName'
                    style={{width: 200}}
                    placeholder='type a new name and'
                    value={this.state.draftGameName}
                    onChange={this.updateState}/>
                    &nbsp;
                <button
                    onClick={this.createGame}>
                    Create Game
                </button>
            </div>

            <p />


            {this.props.selectedGame && <>
                <div class='manage-top'>
                    <div class='manage-question'>
                        Currently displayed question:
                        <Question
                            phase={this.props.selectedGame['currentPhase']}
                            index={this.props.selectedGame['currentQuestion']}
                            q1description={this.props.selectedGame.q1bonus_description}
                            q3description={this.props.selectedGame.q3bonus_description}
                            question={this.getQuestion()} />
                    </div>
                    <div className='manage-controls'>
                        Game controls:
                        <Manage
                            game={this.props.selectedGame}/>
                    </div>

                </div>

                <Grade
                    answers={this.state.answers}
                    game={this.props.selectedGame} />

                <p />

                <Scoreboard
                    scores={this.props.selectedGame['scores']} />

                <p />

                <Create
                    games={this.props.games}
                    game={this.props.selectedGame}/>

            </>}

        </div>;
    }
}

export default Admin;