import React from 'react';
import BaseComponent from "../shared/BaseComponent.js";
import Util from '../shared/Util.js';

class QuestionEditor extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            category: '',
            text: '',
            answer: '',
            focusedField: '',
            duplicates: []
        };
    }

    static getDerivedStateFromProps(props, state) {
        let category = state.focusedField === 'category' ? state.category : props.question.category;
        let text = state.focusedField === 'text' ? state.text : props.question.text;
        let answer = state.focusedField === 'answer' ? state.answer : props.question.answer;

        return {
            category: category,
            text: text,
            answer: answer,
        };
    }

    onFocus = (e) => {
        this.setState({
            focusedField: e.target.name,
        });

        if (e.target.name === 'answer') {
            this.setState({
                duplicates: [],
            });
            e.target.classList.remove('duplicate-answer')
        }
    }

    onBlur = (e) => {
        this.setState({
            focusedField: ''
        });

        this.props.onFieldBlur(e, e.target.name, e.target.value);


        if (e.target.value.length > 0) {
            let matchedAny = false;
            let dupes = []
            this.props.allQuestionAnswerPairs.forEach(pair => {
                const answer = pair.answer
                    .replace(",", "")
                    .replace(".", "")
                    .replace("!", "")
                    .replace("-", "")
                    .replace(";", "")
                    .replace("*", "")
                    .replace("(", "")
                    .replace(")", "")
                    .toLowerCase()
                    .trim();
                const conflictingNewEntry = e.target.value
                    .replace(",", "")
                    .replace(".", "")
                    .replace("!", "")
                    .replace("-", "")
                    .replace(";", "")
                    .replace("*", "")
                    .replace("(", "")
                    .replace(")", "")
                    .toLowerCase()
                    .trim();

                let conflict = false;

                if (answer === conflictingNewEntry) {
                    // Exact match.
                    conflict = true;
                } else if (conflictingNewEntry.length > 5 && answer.indexOf(conflictingNewEntry) > -1) {
                    // 6+ character entered answer is present in another answer.
                    conflict = true;
                } else if (conflictingNewEntry.length > 5 && answer.length > 5 && conflictingNewEntry.indexOf(answer) > -1) {
                    // 6+ character other answer is present in this answer.
                    conflict = true;
                } else if (answer.indexOf(" " + conflictingNewEntry + " ") > -1) {
                    // New entry of any length is in the answer as a separate word.
                    conflict = true;
                } else if (conflictingNewEntry.indexOf(" " + answer + " ") > -1) {
                    // Answer of any length is in the new entry as a separate word.
                    conflict = true;
                } else if (answer.indexOf(conflictingNewEntry + " ") === 0) {
                    // The new entry of any length is at the start of the other answer, as a separate word.
                    conflict = true;
                } else if (conflictingNewEntry.indexOf(answer + " ") === 0) {
                    // The other answer of any length is at the start of the new entry, as a separate word.
                    conflict = true;
                } else if ((answer.indexOf(" " + conflictingNewEntry) > -1)
                    && (answer.indexOf(" " + conflictingNewEntry) === (answer.length - conflictingNewEntry.length - 1))) {

                    // The new entry of any length is at the start of the other answer, as a separate word.
                    conflict = true;
                } else if ((conflictingNewEntry.indexOf(" " + answer) > -1)
                    && (conflictingNewEntry.indexOf(" " + answer) === (conflictingNewEntry.length - answer.length - 1))) {

                    // The new entry of any length is at the start of the other answer, as a separate word.
                    conflict = true;
                }

                if (conflict) {
                    let alreadyThere = false;
                    dupes.forEach(dupe => {
                        if (dupe.duplicateQuestion === pair.question) {
                            alreadyThere = true;
                        }
                    })

                    if (!alreadyThere) {
                        e.target.classList.add("duplicate-answer");
                        dupes.push({
                            duplicateQuestion: pair.question,
                            duplicateExactMatch: answer === conflictingNewEntry,
                            duplicateAnswer: pair.answer
                        });
                        matchedAny = true;
                    }
                }
            });

            this.setState({
                duplicates: dupes
            });

            if (!matchedAny) {
                e.target.classList.remove("duplicate-answer");
                this.setState({
                    duplicateQuestion: ''
                })
            }
        }
    }

    getQuestionPlaceholder = () => {
        if (this.props.phase === 'q1bonus') {
            return 'image, mp3, m4a, mp4 URL #' + (this.props.index + 1);
        } else if (this.props.phase === 'q3bonus') {
            return 'wordy question #' + (this.props.index + 1) + ", or a multimedia URL";
        } else if (this.props.phase === 'halftime') {
            return (10 - (this.props.index * 2)) + ' point question';
        } else {
            return 'question #' + (this.props.index + 1);
        }
    }

    renderDuplicates = () => {
        const dupeRows = [];
        this.state.duplicates.forEach(duplicate => {
            const compareText = duplicate.duplicateExactMatch
                ? <>was the answer to: <p/></>
                : <>
                    is similar to&nbsp;
                    <i>{duplicate.duplicateAnswer}</i>
                    , which was the answer to: <p />
                </>;
            dupeRows.push(<div>{duplicate.duplicateQuestion.length > 0 &&
            <>
                <b>Possible duplicate question! </b>
                <i class='duplicate-answer'>{this.state.answer}</i> {compareText}
                {duplicate.duplicateQuestion}<p/>
                <i class='rule'>You can ignore this warning if it's not helpful.</i>
                <p/>
            </>
            }
            </div>);
        })
        return dupeRows;
    }

    render() {
        return <div class='question-row'>
            {Util.isImageFile(this.state.text) &&
                <img src={this.state.text} />
            }

            {Util.isVideoFile(this.state.text) &&
                <video style={{width: 200}}
                       controls loop preload='auto' src={this.state.text} />
            }

            {Util.isAudioFile(this.state.text) &&
                <audio controls loop preload='auto' src={this.state.text} />
            }

            {
                (this.props.phase.indexOf('bonus') === -1)
                && (this.props.phase.indexOf('halftime') === -1)
                && <textarea
                    placeholder='category'
                    name='category'
                    onChange={this.updateState}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    value={this.state.category} />
            }

            <textarea
                placeholder={this.getQuestionPlaceholder()}
                name='text'
                class='text-editor'
                onChange={this.updateState}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={this.state.text} />

            {/** Show answer field for all non-halftime questions, and for the last halftime clue. */}
            {(this.props.phase !== 'halftime' || this.props.index === 4) &&
            <textarea
                placeholder='answer'
                name='answer'
                onChange={this.updateState}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={this.state.answer}/>
            }

            {this.renderDuplicates()}
        </div>;
    }
}

export default QuestionEditor;