import React from 'react';
import BaseComponent from '../shared/BaseComponent';
import Util from "../shared/Util";

class BonusAnswerCard extends BaseComponent {
    constructor(props) {
        super(props);

        this.state = {
            answers: ['', '', '', '', '', '', '', '', '', '']
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.submittedAnswers.length > 0) {
            return {
                answers: props.submittedAnswers.map(answer => answer.text)
            }
        }
    }

    updateDraftAnswer = (index, answer) => {
        console.log('update draft');
        const answers = this.state.answers;
        answers[index] = answer;

        this.setState({
            answers: answers
        });
    }

    render() {
        return <div id='bonus'>
            {this.props.questions.map((question, index) => {
                const isMultimediaQuestion = Util.isMultimediaFile(question.text);
                const className = isMultimediaQuestion ? 'picturecard' : '';
                return <div class={'semitransparent ' + className}>
                    {isMultimediaQuestion && <br />}
                    <span><b>#{index + 1}</b></span>
                    <p />
                    {Util.isImageFile(question.text) &&
                        <img src={question.text} />}

                    {Util.isAudioFile(question.text) &&
                        <audio controls loop preload='auto' src={question.text} />}

                    {Util.isVideoFile(question.text) &&
                        <video controls loop preload='auto' src={question.text} />}

                    {!isMultimediaQuestion && <> {question.text} <br/> </>}

                    <input
                        type='text'
                        autoComplete='false'
                        value={this.state.answers[index]}
                        onChange={e => this.updateDraftAnswer(index, e.target.value)}
                        placeholder='answer'
                        disabled={this.props.submittedAnswers.length > 0}/>
                </div>
            })}
            <div class='semitransparent'>
                <button
                    disabled={this.props.submittedAnswers.length > 0}
                    onClick={() => this.props.submitAnswers(this.state.answers)}>
                    Submit Answers
                </button>
            </div>
        </div>
    }
}

export default BonusAnswerCard;