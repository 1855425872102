import React from 'react';
import BaseComponent from "../shared/BaseComponent.js";

import Util from '../shared/Util.js';

class Overview extends BaseComponent {
    constructor(props) {
        super(props);
    }

    renderCategoryAndText = (phase, index, question) => {
        const phaseCompare = Util.comparePhases(phase, this.props.selectedGame['currentPhase']);
        const questionAlreadyDone = index <= this.props.selectedGame.currentQuestion;
        const canShowQuestion = phaseCompare < 0 || (phaseCompare === 0 && questionAlreadyDone);

        if (phase === 'q1bonus' || phase === 'q3bonus') {
            return <>
                {Util.htmlForQuestionText(question)} &nbsp;
            </>
        } else if (phase === 'halftime') {
            if (canShowQuestion) {
                return <>
                    {question.text}
                    <br />
                </>
            }
        } else {
            if (canShowQuestion) {
                return <>
                    <b>{question.category}</b>
                    <br/>
                    {question.text}
                    <br />
                </>
            } else {
                return <>
                    <b>{question.category}</b>
                    <br />
                </>
            }
        }
    }

    renderAnswer = (phase, index, question, answer) => {
        if (answer) {
            if (answer.graded) {
                return <div>
                    <i>{question.answer}</i>
                    {answer
                    && question.answer.toLowerCase().trim() !== answer.text.toLowerCase().trim()
                    && <span><i> (you answered "{answer.text}")</i></span>}
                </div>;
            } else {
                return <div>
                    <i>you answered {answer.text}</i>
                </div>;
            }
        }
    }

    getScores = (phase) => {

        const questions = this.props.selectedGame[phase];

        if (!questions) {
            return null;
        }

        const phaseVisible = Util.comparePhases(phase, this.props.selectedGame.currentPhase) <= 0;

        if (!phaseVisible) {
            return;
        }

        const rows = questions.map((question, index) => {
            const thisQuestion = questions[index];
            const answer = this.getAnswerForQuestion(phase, index);

            let gradeDisplay = '';
            let pointsClass = '';

            if (answer && answer.graded) {
                if (answer.gradedPoints === 0) {
                    gradeDisplay = '✗'
                } else if (answer.gradedPoints === answer.points) {
                    gradeDisplay = '✓';
                } else {
                    gradeDisplay = answer.gradedPoints;
                    pointsClass = 'points-modified'
                }
            }

            const className = !answer ? '' :
                !answer.graded ? 'grading' :
                    answer.gradedPoints > 0 ? 'correct' : 'incorrect';
            return <tr class={className}>
                <td class='overview-cell'>
                    <div>
                        {this.renderCategoryAndText(phase, index, thisQuestion)}
                        {this.renderAnswer(phase, index, thisQuestion, answer)}
                    </div>
                </td>
                <td class={pointsClass}>{answer && answer.points}</td>
                <td>{gradeDisplay}</td>
            </tr>
        });

        const hasCategory = !Util.isBonus(phase) && !Util.isHalftime(phase);
        return <>
            <b className='phase-name'>{Util.getPhaseString(phase)}</b>
            <table>
                <colgroup>
                    {
                        hasCategory
                        && <>
                            <col width='30%' />
                            <col width='50%' />
                        </>
                    }

                    {
                        !hasCategory
                        && <>
                            <col width='80%' />
                        </>
                    }

                    <col width='10%' />
                    <col width='10%' />
                </colgroup>
                {rows}
            </table>
        </>
    }

    getAnswerForQuestion = (phase, index) => {
        return this.props.myAnswers
            .find(answer => answer.phase === phase && answer.question === index)
    }

    render() {
        return <div id='overview' class='semitransparent'>
            <div>
            {this.getScores('finals')}

            {this.getScores('q4')}

            {this.getScores('q3bonus')}

            {this.getScores('q3')}

            {this.getScores('halftime')}

            {this.getScores('q2')}

            {this.getScores('q1bonus')}

            {this.getScores('q1')}

            </div>
        </div>
    }
}

export default Overview;