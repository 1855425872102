import React from 'react';
import BaseComponent from "./BaseComponent";
import Chart from "chart.js";

class Scoreboard extends BaseComponent {
    constructor(props) {
        super(props);

        this.colors = [
'#F44336', // Red
'#FF9800', // Orange
'#FFEB3B', // Yellow
'#4CAF50', // Green
'#2196F3', // Light Blue
'#9C27B0', // Purple
'#795548', // Brown
'#E91E63', // Pink
'#BDBDBD', // Gray
'#FF5722', // Dark Orange
'#8BC34A', // Light Green
'#3F51B5', // Dark Blue
'#CDDC39', // Lime Green
'#009688', // Teal
'#00BCD4', // Cyan
'#673AB7', // Darker Purple
'#000000',
'#222222', 
'#444444',
'#666666'
        ];
    }

    componentDidMount = () => {
        this.renderGraph();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.renderGraph();
    }

    renderGraph = () => {
        const datasets = []


        const orderedScores = {};
        Object.keys(this.props.scores).sort().forEach((key) => {
            orderedScores[key] = this.props.scores[key];
        });

        let index = 0;
        for (const team in orderedScores) {
            const scoreObject = orderedScores[team];
            const dataObject = {
                data: scoreObject.scoreHistory.slice(),
                label: team,
                fill: false,
                borderColor: this.colors[index],
                backgroundColor: this.colors[index]
            };
            dataObject.data.unshift(0);
            datasets.push(dataObject);

            index++;
        }

        const data = {
            labels: [
                'start',
                'q1', '', '', '',
                'bonus',
                'q2', '', '', '',
                'halftime',
                'q3', '', '', '',
                'bonus',
                'q4', '', '', '',
                'final 1', 'final 2'],
            datasets: datasets,
        };

        if (!this.chart) {
            this.chart = new Chart(document.getElementById("line-graph"), {
                type: 'line',
                data: data,
                options: {
                    responsive: true,
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                    },
                    hover: {
                        mode: 'nearest',
                        intersect: true
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: 10
                            }
                        }]
                    },
                    elements: {
                        line: {
                            tension: 0
                        }
                    },
                    maintainAspectRatio: false,
                    animation: false
                }
            });
        } else {
            this.chart.config.data = data;
            this.chart.update();
        }
    }

    render() {
        return <div id='graph' class='semitransparent'>
            <canvas id="line-graph"></canvas>
        </div>;
    }
}

export default Scoreboard;