import React from 'react';
import BaseComponent from "./BaseComponent";

class GameSelector extends BaseComponent {
    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedGameId && props.games.find(game => game.id === props.selectedGameId)) {
            return {
                selectedGameId: props.selectedGameId
            }
        } else {
            return {
                selectedGameId: ''
            }
        }
    }

    render() {
        return <select
            name='selectedGameId'
            value={this.state.selectedGameId}
            disabled={this.props.disabled}
            onChange={e => {
                this.setState({
                    selectedGameId: e.target.value
                });

                const id = e.target.value;
                this.props.onSelectedGameIdChanged(
                    id, this.props.games.find(game => game.id === id))
            }}>
            <option disabled selected value=''>select a game...</option>
            {this.props.games.map(game =>
                <option value={game.id}>{game.name}</option>
            )}
        </select>;
    }
}

export default GameSelector;