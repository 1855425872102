import React from 'react';
import BaseComponent from '../shared/BaseComponent';

import Util from "../shared/Util";

class Question extends BaseComponent {
    render() {
        if (this.props.question) {
            return <div id='question' class='semitransparent'>
                {
                    Util.getCategoryString(
                        this.props.question.category, this.props.phase, this.props.index)
                }

                <p/>

                {/** Normal quarters, halftime, and finals. */}
                {!Util.isBonus(this.props.phase) &&
                this.props.question.text}

                {/** Picture round. */}
                {this.props.phase === 'q1bonus' &&
                this.props.q1description}

                {/** Wordy round. */}
                {this.props.phase === 'q3bonus' &&
                this.props.q3description}

                {/** Show the rules if we have them! */}
                {Util.getRulesString(this.props.phase) &&
                <>
                    <p/>
                    <i class='rule'>{Util.getRulesString(this.props.phase)}</i>
                </>
                }
            </div>
        } else {
            return <div id='question' className='semitransparent'>
                This game hasn't started yet. It'll probably be just a few more minutes.
            </div>
        }
    }
}

export default Question;