import firebase from 'firebase'
const config = {
  apiKey: "AIzaSyBImCy3TDRoXlGH6R3goV8StvGjYIeZmUU",
  authDomain: "fishytrivia.firebaseapp.com",
  databaseURL: "https://fishytrivia.firebaseio.com",
  projectId: "fishytrivia",
  storageBucket: "fishytrivia.appspot.com",
  messagingSenderId: "124487676494",
  appId: "1:124487676494:web:7eea0eb735a7e2f7aa891f"
};
firebase.initializeApp(config);
export default firebase;
