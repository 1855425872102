import React, {Component} from 'react';
import './v2/style/global.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Play from "./v2/play/Play";
import firebase from "./firebase";
import Admin from './v2/admin/Admin.js';
import DumpQuestions from './v2/admin/DumpQuestions.js';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            games: [],
            selectedGameId: window.localStorage.getItem('selectedGameId'),
            selectedGame: null
        };

        firebase.firestore()
            .collection('games')
            .orderBy('created', 'desc')
            .onSnapshot((games) => {
                const allGames = [];
                games.forEach((gameDocument) => {
                    const game = gameDocument.data();
                    game['id'] = gameDocument.id;
                    allGames.push(game);
                })

                this.setState({
                    games: allGames
                })

                const gameId = this.state.selectedGameId;

                this.setState({
                    selectedGameId: gameId
                });

                const newGame = this.state.games.find(game => game.id === gameId);
                this.setState({
                    selectedGame: newGame
                });

                if (newGame && newGame.currentPhase === 'q1bonus') {
                    document.body.className='lowprofile';
                } else {
                    document.body.className = '';
                }
            })
    }

    onSelectedGameIdChanged = (gameId) => {
        window.localStorage.setItem('selectedGameId', gameId);
        window.location.reload();
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/dumpquestions">
                        <DumpQuestions
                            games={this.state.games} />
                    </Route>
                    <Route path="/admin">
                        <Admin
                            games={this.state.games}
                            selectedGame={this.state.selectedGame}
                            selectedGameId={this.state.selectedGameId}
                            onSelectedGameIdChanged={this.onSelectedGameIdChanged} />
                    </Route>
                    <Route path="/">
                        <Play
                            games={this.state.games}
                            selectedGame={this.state.selectedGame}
                            selectedGameId={this.state.selectedGameId}
                            onSelectedGameIdChanged={this.onSelectedGameIdChanged} />
                    </Route>
                </Switch>
            </Router>)
    }
}

export default App;
